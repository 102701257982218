h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %sheng-heading !optional;
}

b,
  strong,
  dt,
  th {
    @extend %sheng-bold !optional;
  }

:not(pre) > code {
  @extend %sheng-inline-code !optional;
}

:not(pre) > code,
pre {
  @extend %sheng-code !optional;
}

@mixin sheng($scale-ratio: 4/3, $base-font-size: 20px, $paragraph-spacing: 1) {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  font-size: $base-font-size;
  line-height: 1.5;
  overflow-wrap: break-word;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";

  %sheng-bold {
    font-weight: bold;
    @extend %sheng-bold !optional;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup,
  ul,
  ol,
  dl,
  dd,
  p,
  figure,
  pre,
  table,
  fieldset,
  blockquote,
  form,
  noscript,
  iframe,
  img,
  hr,
  address {
    margin-bottom: $paragraph-spacing * $base-font-size;
  }

  hr {
    background: currentColor;
    border: none;
    height: 1px;
    margin-bottom: $paragraph-spacing * $base-font-size - 1px;
  }

  ol,
  ul {
    list-style-position: outside;
  }

  %sheng-heading {
    font-weight: bold;
    margin-top: 2em;
  }
  h1 {
    font-size: $scale-ratio * $scale-ratio * $base-font-size;
    line-height: 1.125;
  }
  h2 {
    font-size: $scale-ratio * $base-font-size;
    line-height: 1.25;
  }
  h3,
  h4,
  h5,
  h6 {
    font-size: $base-font-size;
    line-height: 1.5;
  }

  %sheng-code {
    font-family: monospace;
    font-size: 0.9em;
  }

  ul {
    list-style-type: disc;
    margin-left: 1em;
  }
  ol {
    list-style-type: numeric;
    margin-left: 1em;
  }
}
